.transparent-nav {
  background: rgba(255, 255, 255, 0);
  position: fixed;
  width: 100%;
  z-index: 1000; /* Ensure it is on top */
  transition: background 0.3s;
}

.nav-link {
  font-family: 'Fira Sans', sans-serif;
  font-weight: 600 !important;
  font-style: normal;
  text-transform: uppercase;
}

.nav-link-edit {
  font-family: 'Fira Sans', sans-serif;
  margin-left: 0.5rem;
  color: darkslategray !important;
  background-color: white !important;
  font-weight: 600 !important;
  font-style: normal;
  text-transform: uppercase;
}

.dark-font {
  color: darkslategray !important;
}

.light-font {
  color: white !important;
}

.navbar-collapse {
  justify-content: center;
}

.navbar-nav {
  flex-grow: 1;
  justify-content: center;
}

.navbar-collapse {
  flex-grow: 1;
}

.navbar-toggler {
  align-self: flex-end;
}
