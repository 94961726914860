.hero-section {
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full width of the parent container */
  background-image: url('https://roopesalminen.fi/img/roope-portrait.jpg');
  background-size: cover;
  background-position: top center;
  position: relative; /* Ensure positioning context for absolute children */
  display: flex;
  align-items: flex-end; /* Align items to the bottom */
  justify-content: center; /* Center horizontally */
}

.photo-credit {
  position: absolute;
  bottom: 1rem; /* Adjust as needed */
  right: 1rem; /* Adjust as needed */
  color: black;
  padding: 5px 10px; /* Padding around the text */
  font-size: 0.8em; /* Smaller text size */
}

/* Medium Devices (Small Laptops, ≥768px) */
@media (min-width: 768px) {
  .hero-section {
    background-image: url('https://roopesalminen.fi/img/roope-portrait-xl.jpg');
  }
}

.hero-content {
  width: 100%; /* Full width */
  position: absolute;
  color: black;
  background-color: rgba(255, 255, 255, 0.5); /* 50% transparency */
  bottom: 12%;
  padding: 1rem;
}

/* Medium Devices (Small Laptops, ≥768px) */
@media (min-width: 992px) {
  .hero-content {
    background-color: rgba(255, 255, 255, 0);
  }
}