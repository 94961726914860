.App {
  text-align: center;
  position: relative;
}

h1 {
  font-family: "Fira Sans", sans-serif;
  font-weight: 600 !important;
  font-style: normal;
  text-transform: uppercase;
}

h2 {
  font-family: "Fira Sans", sans-serif;
  font-weight: 200 !important;
  font-style: normal;
}

p {
  font-family: "Fira Sans", sans-serif;
  font-weight: 200;
  font-style: normal;
}

label {
  font-family: "Fira Sans", sans-serif;
  font-weight: 200;
  font-style: normal;
}

small {
  font-family: "Fira Sans", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-padding {
  padding: 0 !important; /* Removes padding */
}

.no-margin {
  margin: 0 !important; /* Removes margin */
}

.full-width {
  width: 100vw; /* Sets the width to 100% of the viewport width */
  max-width: 100%; /* Ensures no overflow */
  overflow-x: hidden; /* Prevents horizontal scroll */
}

.post {
  background-color: darkslategrey;
  color: white;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.admin-title {
  color: white;
}