.cookie-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cookie-banner p {
  margin: 0;
  padding: 0 10px;
  flex: 1;
}

.btn-accept {
  background-color: #38AF7E;
  color: white;
  border: none;
  padding: .5rem 1rem;
  margin: 1rem;
  cursor: pointer;
}

.btn-decline {
  background-color: #FA5E83;
  color: white;
  border: none;
  padding: .5rem 1rem;
  margin: 1rem;
  cursor: pointer;
}
