.lavalla-section {
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full width of the parent container */
}

.show-social {
  position: absolute;
  text-align: left;
  left: 15%;
  top: 10%
}

.hide-social {
  display: none;
}

.social-icon {
  margin: 0 10px;
  font-size: 1.4rem;
  color: #333;
  text-decoration: none;
}

.social-icon:hover {
  color: white;
}

.carousel-control-next-icon {
  background-color: rgba(128, 128, 128, 0.9);
  padding: 1rem !important;
}

.carousel-control-prev-icon {
  background-color: rgba(128, 128, 128, 0.9);
}

.carousel-caption {
  background-color: rgb(245, 245, 245);
  padding: 1rem;
  bottom: 12% !important;
  right: 0% !important;
  left: 0% !important;
}

.carousel-caption p {
  color: black !important;
}

.carousel-caption h3 {
  color: black !important;
}

.carousel-flappi {
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full width of the parent container */
  background-image: url("https://roopesalminen.fi/img/flappi.jpg");
  background-size: cover;
  background-position: top center;
}

.carousel-komedia {
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full width of the parent container */
  background-image: url("https://roopesalminen.fi/img/carousel-bg.jpg");
  background-size: cover;
  background-position: top center;
}

.carousel-koirat {
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full width of the parent container */
  background-image: url("https://roopesalminen.fi/img/koirat.jpg");
  background-size: cover;
  background-position: top center;
}

.carousel-muut {
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full width of the parent container */
  background-image: url("https://roopesalminen.fi/img/muut.jpg");
  background-size: cover;
  background-position: top center;
}

/* Medium Devices (Small Laptops, ≥768px) */
@media (min-width: 768px) {
  .carousel-komedia {
    background-image: url("https://roopesalminen.fi/img/carousel-bg-xl.jpg");
  }

  .carousel-flappi {
    background-image: url("https://roopesalminen.fi/img/flappi-xl.jpg");
  }
}

/* Large Devices (Small Laptops, ≥992px) */
@media (min-width: 992px) {
  .carousel-komedia {
    background-image: url("https://roopesalminen.fi/img/carousel-bg-xxl.jpg");
  }
  .carousel-caption {
    background-color: rgb(245, 245, 245);
    padding: 1rem;
    bottom: 0% !important;
    right: 0% !important;
    left: 0% !important;
  }
}

/* X-Large Devices */
@media (min-width: 1200px) {
  .carousel-komedia {
    background-image: url("https://roopesalminen.fi/img/carousel-bg-xxxl.jpg");
  }
  .carousel-flappi {
    background-image: url("https://roopesalminen.fi/img/flappi-xxl.jpg");
  }

  .carousel-caption {
    background-color: rgb(245, 245, 245);
    padding: 1rem;
    bottom: 0% !important;
    right: 0% !important;
    left: 0% !important;
  }
}