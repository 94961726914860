.tilauslomake {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.form-label {
    margin-top: 0.5rem;
}

.tietosuoja {
    margin-top: .5rem;
}

.tietosuoja a {
    cursor: pointer;
    color: rgb(46,79,79) !important;
    text-decoration: none !important;
}