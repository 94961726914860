.footer {
    text-align: center;
    padding: 20px;
    margin-top: 20px;
    background-color: #000000; /* Light gray background, adjust as needed */
}

.footer p {
    margin: 0;
    font-size: 14px; /* Small text size for the footer */
    color: #666; /* Dark gray text, adjust as needed */
}

.social-icon {
    margin: 0.2rem;
}
